import { useSiteContext, useSiteTitle } from '@dustin-web/microsite-components/src/hooks'
import Head from 'next/head'
import { getBrandFallback } from 'src/components/pages/Brand'
import {
  type ArticlePageFragment,
  type BlogPageFragment,
  type BrandContentPageFragment,
  type BrandListPageFragment,
  type BrandPageFragment,
  type CustomerProductGroupPageFragment,
  type CustomerStartPageFragment,
  type EditorialPageFragment,
  type GetPageQuery,
  type GetPageQueryVariables,
  PageType,
  Theme,
} from 'src/graphql/generated/graphql-types'
import useCleanRouter from '@dustin-web/microsite-components/src/hooks/use-clean-router'
import BaseLayout from '@dustin-web/microsite-baselayout/src/components/base-layout'
import devUtils from 'src/dev-utils.json'
import { withMicrositeProps } from '@dustin-web/microsite-nextjs/src/with-server-data/with-microsite-props'
import { withMicrositeWrapper } from '@dustin-web/microsite-nextjs/src/with-server-data/with-microsite-wrapper'
import { log } from '@dustin-web/microsite-config/src/logger'
import { useGraphql } from '@dustin-web/microsite-data/src/hooks/use-graphql'
import { GetPage } from 'src/graphql/get-page.graphql'
import { getCleanContentPageUrl } from 'src/components/utils/page-urls'
import { isPreviewRequest, PreviewInfoBar, usePreviewMode } from 'src/components/utils/preview'
import { load } from 'cheerio'
import { getCampaignProductListingFallback } from 'src/components/macros/CampaignProductListing'
import { useI18n } from '@dustin-web/microsite-components/src/i18n'
import { getStartFallback } from 'src/components/utils/getStartFallback'
import { getStartpageVersion } from 'src/components/utils/getStartPageVersion'
import classnames from 'classnames'
import dynamic from 'next/dynamic'

const StartpageWrapper = dynamic(import('src/components/pages/startpage/startpage-wrapper'))
const Editorial = dynamic(() => import('src/components/pages/Editorial'))
const Blog = dynamic(() => import('src/components/pages/Blog'))
const Article = dynamic(() => import('src/components/pages/Article'))
const BrandList = dynamic(() => import('src/components/pages/BrandList'))
const Brand = dynamic(() => import('src/components/pages/Brand'))
const BrandContent = dynamic(() => import('src/components/pages/BrandContent'))
const Customer = dynamic(() => import('src/components/pages/Customer'))

const ContentPage = () => {
  const { getSiteTitle } = useSiteTitle()
  const { pageUri: pageUriWithQuery } = useCleanRouter()
  const { translate } = useI18n()
  const { siteUrl, isBusiness } = useSiteContext()
  const { preview } = usePreviewMode()

  const pageUri = getCleanContentPageUrl(pageUriWithQuery) || ''

  const { data, error } = useGraphql<GetPageQuery, GetPageQueryVariables>(GetPage, {
    variables: { uri: pageUri, preview },
  })

  const page = data?.page

  if (error) {
    // eslint-disable-next-line no-console
    console.log({ error, page, pageUri })
    return <div>Error: {error?.message ?? 'Not found'}</div>
  }

  if (!page) {
    return null
  }

  let pageContent = <h1>Unsupported page type: {page.type}</h1>
  let theme: string | undefined

  if (page.type === PageType.Start) {
    pageContent = <StartpageWrapper />
  } else if (page.type === PageType.Editorial) {
    const editorialPage = page as EditorialPageFragment
    pageContent = <Editorial page={editorialPage} />
    theme = editorialPage.theme
  } else if (page.type === PageType.Blog) {
    pageContent = <Blog page={page as BlogPageFragment} />
  } else if (page.type === PageType.Article) {
    pageContent = <Article page={page as ArticlePageFragment} translate={translate} />
  } else if (page.type === PageType.BrandList) {
    pageContent = <BrandList page={page as BrandListPageFragment} />
  } else if (page.type === PageType.Brand) {
    pageContent = <Brand page={page as BrandPageFragment} />
  } else if (page.type === PageType.BrandContent) {
    pageContent = <BrandContent page={page as BrandContentPageFragment} />
  } else if (page.type === PageType.CustomerStart) {
    pageContent = <Customer page={page as CustomerStartPageFragment} />
  } else if (page.type === PageType.CustomerProductGroup) {
    pageContent = <Customer page={page as CustomerProductGroupPageFragment} />
  }

  const canonicalUrl = getCanonicalUrl(siteUrl || '', pageUri)

  const head = (
    <Head>
      <title>{getSiteTitle(page.title)}</title>

      {page.metaDescription && <meta name="description" content={page.metaDescription} />}
      {page.metaKeywords && <meta name="keywords" content={page.metaKeywords} />}
      {page.noIndex && <meta name="robots" content="noindex" />}

      <meta property="og:title" content={page.title} />
      <meta property="og:url" content={canonicalUrl} />
      {page.metaDescription && <meta name="og:description" content={page.metaDescription} />}
      {page.ogImage && <meta property="og:image" content={page.ogImage.url} />}

      <link rel="canonical" href={canonicalUrl} />
    </Head>
  )

  return (
    <>
      {preview && <PreviewInfoBar />}
      <BaseLayout disableSiteSelector={page.disableSiteSelector} devUtils={devUtils}>
        {head}
        <div className={classnames('cms-content', getThemeClass(theme, isBusiness))}>
          {pageContent}
        </div>
      </BaseLayout>
    </>
  )
}

const getThemeClass = (theme?: string, isBusiness?: boolean) => {
  if (theme === Theme.BlackWeek) {
    return isBusiness ? 't-campaign-page' : 't-campaign-page t-campaign-page--consumer'
  }
}

const getCanonicalUrl = (siteUrl: string, pageUri: string) => {
  const baseUrl = siteUrl
  const pageUrl = pageUri ?? ''
  const fullUrl = baseUrl + pageUrl

  return fullUrl
}

export const getServerSideProps = withMicrositeProps(async data => {
  const { req, resolvedUrl, micrositeProps } = data
  const { requestContext, micrositeSettings, userSettings, fetchGraphql, query, cookies } =
    micrositeProps
  let props = {
    host: req.headers.host ?? '',
    fallback: {},
  }

  const preview = isPreviewRequest(req)
  const pageUri = getCleanContentPageUrl(resolvedUrl) || ''
  try {
    const [key, page] = await fetchGraphql<GetPageQuery, GetPageQueryVariables>(GetPage, {
      variables: { uri: pageUri, preview },
    })

    let pageSpecificFallback = {}

    if (page.page?.type === PageType.Start) {
      const panelPath = getStartpageVersion(
        userSettings.personalizationConsent,
        micrositeSettings.isBusiness
      )?.panelPath

      pageSpecificFallback = await getStartFallback(fetchGraphql, preview, panelPath)
    }

    if (page.page?.type === PageType.Brand) {
      pageSpecificFallback = await getBrandFallback(
        page.page as BrandPageFragment,
        query,
        fetchGraphql
      )
    }

    if (page.page?.type === PageType.Editorial) {
      const parsedPage = page.page as EditorialPageFragment

      if (parsedPage.body.includes('<?UMBRACO_MACRO')) {
        const $ = load(parsedPage.body.split('<?UMBRACO_MACRO').join('<embed'))
        const macros: Record<string, string>[] = []
        $('embed').each((_, element) => {
          const attributes = $(element).attr()
          if (attributes) {
            macros.push(attributes)
          }
        })

        // Only handle one type of these macros. Should never have more than one.
        const promotedCampaignListingMacros = macros.find(
          x => x['macroalias'] === 'CampaignProductListing'
        )

        if (promotedCampaignListingMacros && promotedCampaignListingMacros['activityid']) {
          pageSpecificFallback = await getCampaignProductListingFallback(
            query,
            cookies,
            parseInt(promotedCampaignListingMacros['activityid']),
            fetchGraphql
          )
        }
      }
    }

    props.fallback = { [key]: page, ...pageSpecificFallback }

    return { props }
  } catch (error: any) {
    // TODO: Find out if the page is actually 404 or a real error
    log.warn({ err: error, traceIdentifier: requestContext.correlationId }, 'Page not found')

    if (error?.response?.errors?.some((err: any) => err.extensions?.code === 'UNAUTHORIZED')) {
      return { redirect: { destination: `/login?returnUrl=${pageUri}`, permanent: false }, props }
    }
    return { notFound: true, props }
  }
})

export default withMicrositeWrapper(ContentPage, { useDefaultBaseLayout: false })
